import request from '@http';

// 2022/12/22 王江毅 商户信息列表
export function getServiceInfoList() {
    return request({
        method: 'GET',
        url: '/api/gzf/build/get/service/info/list',
    });
}

// 2022/12/22 王江毅 支付信息列表
export function getServiceOrderList(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/build/mircopay/service/order/list',
        params,
    });
}