import {timeFormat, numberFormat} from "@custom";

export const tableColumn = [
    {
        type: "index",
        label: "序号",
        width: 100
    },
    {
        prop: "orderNo",
        label: "订单号",
        width: 250,
    },
    {
        prop: "merchantName",
        label: "商户号简称",
        width: 200
    },
    {
        prop: "userName",
        label: "用户名",
        width: 100
    },
    {
        prop: "payStatus",
        label: "状态",
        width: 100,
    },
    {
        prop: "channel",
        label: "渠道"
    },
    {
        prop: "type",
        label: "类型"
    },
    {
        prop: "total",
        label: "充值金额(元)",
        render: (h, {row}) => {
            return h("span", !row.orderAmount ? "" : numberFormat(row.orderAmount / 100));
        },
    },
    {
        label: "手续费",
        render: (h, {row}) => {
            let {feeAmount} = row;
            return h("span", numberFormat((feeAmount) / 100));
        },
        renderHeader: (h, {column, $index}) => {
            return h(
                "el-tooltip",
                {
                    props: {
                        content: "手续费=充值金额*0.2%+充值金额*0.4%",
                        placement: "top",
                        effect: "light",
                    },
                    //最后展示的内容是 icon 图标 + 列的 label
                    domProps: {
                        innerHTML: `${column.label} <i class="el-icon-question"/>`,
                    },
                }, [h("span")]
            );
        }
    },
    {
        prop: "recordedAmount",
        label: "入账金额(元)",
        render: (h, {row}) => {
            return h("span", !row.amount ? "" : numberFormat(row.amount / 100));
        },
    },
    {
        prop: "actionTime",
        label: "充值时间",
        render: (h, {row}) => {
            return h("span", !row.actionTime ? "" : timeFormat(new Date(Number(row.actionTime)), 'yyyy-MM-dd HH:mm:ss'));
        },
    }
];
